// Terms.js
import React from 'react';
import 'tailwindcss/tailwind.css';

function Terms() {
  return (
    <div>
      <h1 className="title text-2xl font-bold mb-4">利用規約</h1>
      <p className="mb-4">
        本利用規約（以下「本規約」といいます。）には、当社とインフルエンサー又は業務受託者（以下総称して「本規約同意者」といいます。）の皆様との間の
        権利義務関係が定められています。本規約同意者としての申請に際しては、本規約の全
        文をお読みいただいた上で、本規約に同意いただく必要があります。
      </p>
      <h2 className="text-xl font-semibold mb-2">
       第 1 条（適用）
      </h2>
      <ol className="list-decimal list-inside mb-4">
       <li className="mb-2">
         本規約は、本規約同意者業務の委託に関する本規約同意者の間の権利
         義務関係を定めることを目的とし、本規約同意者当社との間の本規約同意者
         の業務に委託に関わる一切の関係に適用されます。
       </li>
       <li className="mb-2">
         本規約の内容と、本規約外における本規約同意者の業務に関する説明等とが異なる
         場合は、本規約の規定が優先して適用されるものとします。
       </li>
       <li className="mb-2">
         本規約は、本媒体（第 2 条で定義されます。）の運営会社が定める利⽤規約・ガイドラ
         イン等（以下「本媒体規約」といいます。）と⽭盾・抵触しない範囲で効⼒を有し、本規
         約に基づく本基本契約⼜は本個別契約（いずれも第 2 条で定義されます。）上の債務の履
         ⾏に際して本媒体規約に違反する恐れがある場合、当社及び本規約同意者は協議の
         上、本基本契約⼜は本個別契約の内容を変更することができます。
       </li>
      </ol>


      <h2 className="text-xl font-semibold mb-2">
       第 2 条（定義）
      </h2>
      <p className="mb-2">
      本規約において使⽤する以下の⽤語は、各々以下に定める意味を有するものとします。
      </p>
      <ul className="mb-4">
       <li className="mb-2">
         ①「本基本契約」とは、本規約に基づき当社と本規約同意者間で締結される、
         本規約同意者への業務の委託に関する基本契約を意味します。
       </li>
       <li className="mb-2">
         ②「本個別契約」とは、第５条に従い当社と本規約同意者間で締結される契約をいい
         ます。
       </li>
       <li className="mb-2">
         ③「知的財産権」とは、著作権、特許権、実⽤新案権、意匠権、商標権その他の知的財産権
         （それらの権利を取得し、⼜はそれらの権利につき登録等を出願する権利を含みます。）を
         意味します。
       </li>
       <li className="mb-2">
        ④「当社」とは、株式会社ミンツプランニングを意味します。
       </li>
       <li className="mb-2">
        ⑤ 「当社ウェブサイト」とは、そのドメインが「mintzplanning.com」である、当社が運営
         するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメイン⼜は内容が変更さ
         れた場合は、当該変更後のウェブサイトを含みます。）を意味します。
       </li>
       <li className="mb-2">
        ⑥ 「本業務」とは、本個別契約に基づいて本規約同意者それぞれが行う下記業務を意味します。
        <p className="ml-4">インフルエンサーの場合</p>
        <p className="ml-4">(i)本成果物を制作する業務、及び(ii)本成果物を本媒体に投稿する業務並びに(i)及び(ii)に付随又は関連する業務を含みますが、これらに限りません</p>
        <p className="ml-4">業務受託者の場合</p>
        <p className="ml-4">(i)クリエイティブチームに関する業務、(ii)SNSに関するコンサルティング、ラフ案制作、テキスト‧ハッシュタグ作成など又は(ⅲ)営業企画実務全般及び(i)、(ii)、(ⅲ)に付随又は関連する業務を含みますが、これらに限りません。</p>
       </li>
       <li className="mb-2">
        ⑦ 「本業務」とは、本個別契約に基づいてインフルエンサーが⾏う業務を意味し、(i)本成果
         物を制作する業務、及び(ii)本成果物を本媒体に投稿する業務並びに(i)及び(ii)に付随⼜は
         関連する業務を含みますが、これらに限りません。
       </li>
       <li className="mb-2">
        ⑧「インフルエンサー」とは、第3条に基づいてインフルエンサーとしての申請がなされた 個人を意味します。
       </li>
       <li className="mb-2">
        ⑨「業務受託者」とは、第３条に基づいて、業務受託者としての申請がなされた  個人を含みます。
       </li>
       <li className="mb-2">
        ⑩ 「クライアント」とは、本サービスの利⽤者である法⼈⼜は個⼈を意味します。
       </li>
       <li className="mb-2">
        ⑪「電⼦媒体」とは、インターネットを利⽤し、特定⼜は不特定を問わず複数のユーザーに
        対し、⼀定の情報を配信する媒体となる⼿段（電⼦メールを含みます。）をいいます。
       </li>
       <li className="mb-2">
        ⑫「本成果物」とは、電子媒体を利用し、クライアントの名称、商品、サービス名、販促キャンペーンその他の広告及び宣伝事項等をユーザーに伝達するために、本個別契約に基づきインフルエンサーが制作した広告及びインフルエンサーによる本業務の提供の過程で生じた制作物並びに業務受託者が本個別契約に基づき制作、納品したものをいいます。
       </li>
       <li className="mb-2">
       ⑬ 「本媒体」とは、インフルエンサーによる本成果物を掲載する SNS（Twitter、Instagram を含みますがこれらに限
        りません。）等の電⼦媒体をいいます。
       </li>
       <li className="mb-2">
       ⑭「二次利用」とは、インフルエンサーが制作した本成果物をインフルエンサー自身のアカ ウント以外で利用することをいいます。
       </li>
      </ul>


      <h2 className="text-xl font-semibold mb-2">
      第 3 条（申請）
      </h2>

      <ol className="list-decimal list-inside mb-4">
       <li className="mb-2">
        本規約同意者としての取引開始を希望する者（以下「取引開始希望者」といいま
        す。）は、本規約を遵守することに同意し、かつ当社の定める⼀定の情報（以下「申請事項」
        といいます。）を当社の定める⽅法で当社に提供することにより、当社に対し、本規約同意者
        としての取引開始を申請することができます。
       </li>
       <li className="mb-2">
        当社は、当社の基準に従って、前項に基づいて取引開始申請を⾏った希望者（以下「申
        請者」といいます。）の申請の可否を判断し、当社が申請を認める場合にはその旨を申請者
        に通知します。申請者の本規約同意者としての申請は、当社が本項の通知を⾏ったこと
        をもって完了したものとします。
       </li>
       <li className="mb-2">
        前項に定める取引開始申請の完了時に、本基本契約が当社と本規約同意者の間に
        成⽴します。
       </li>
       <li className="mb-2">
        当社は、本規約同意者が、以下の各号のいずれかの事由に該当する場合は、申請及び再申請を
        拒否することがあり、またその理由について⼀切開⽰義務を負いません。
       </li>
      </ol>
      <ul className="mb-4">
       <li className="mb-2">
        ①当社に提供した申請事項の全部⼜は⼀部につき虚偽、誤記⼜は記載漏れがあった場合
       </li>
       <li className="mb-2">
        ②未成年者、成年被後⾒⼈、被保佐⼈⼜は被補助⼈のいずれかであり、法定代理⼈、後⾒⼈、保佐⼈⼜は補助⼈の同意等を得ていなかった場合
       </li>
       <li className="mb-2">
        ③反社会的勢⼒等（暴⼒団、暴⼒団員、反社会的勢⼒（第 28 条において定義されます。）、その他これに準ずる者を意味します。以下同じ。）である、⼜は資⾦提供その他を通じて反社会的勢⼒等の維持、運営若しくは経営に協⼒若しくは関与する等反社会的勢⼒等との何らかの交流若しくは関与を⾏っていると当社が判断した場合
       </li>
       <li className="mb-2">
        ④過去当社との契約に違反した者⼜はその関係者であると当社が判断した場合
       </li>
       <li className="mb-2">
        ⑤第 19 条に定める措置を受けたことがある場合
       </li>
       <li className="mb-2">
        ⑥その他、当社が申請を適当でないと判断した場合
       </li>
      </ul>



     <h2 className="text-xl font-semibold mb-2">
     第 4 条（申請事項の正確性）
     </h2>
     <ol className="list-decimal list-inside mb-4">
      <li className="mb-2">
        本規約同意者は、当社に対し、申請事項（個⼈情報（個⼈情報の保護に関する法律
        に定める「個⼈情報」をいいます。以下同じ。）を含みます。）を不備・齟齬のないよう正確
        に提供するものとします。本規約同意者が提供した申請事項が正確でなかったこと、及
        び、その内容の不備・齟齬などに起因して第三者から何らかの異議、請求若しくは要求など
        がなされた場合には、本規約同意者は、⾃⼰の費⽤負担と責任で対処するものとし、当
        社に⼀切の迷惑をかけないことを保証するものとします。
      </li>
      <li className="mb-2">
       本規約同意者は、申請事項に変更があった場合、当社の定める⽅法により当該変更
     事項を遅滞なく当社に通知するものとします。
      </li>
      <li className="mb-2">
       本規約同意者は、当社が求めた場合には、以下の書類を速やかに提出するものとし
       ます。
       <ul className="mb-4">
        <li className="mb-2">
         ①⽒名・住所等、本規約同意者であることを特定・確認するための情報が記載された本⼈確認書類
        </li>
        <li className="mb-2">
         ②在留資格を確認するための在留カード⼜は就労資格証明書等
        </li>
        <li className="mb-2">
         ③その他当社が提出を求める証明書類
        </li>
        </ul>
      </li>
    </ol>


      <h2 className="text-xl font-semibold mb-2">
        第５条（本個別契約）
      </h2>

      <ol className="list-decimal list-inside mb-4">
       <li className="mb-2">
        当社及び本規約同意者は、本個別契約において、本業務に関する個別具体的な事項
        を定めるものとします。なお、当該本個別契約において本規約と異なる定めをした場合に
        は、当該本個別契約が本規約に優先します。
       </li>
       <li className="mb-2">
        前項の本個別契約については、当社が、取引条件を記載した当社所定の書式によって
        本規約同意者に対して通知（LINE、電⼦メール⼜はその他の電磁的⽅法によることがで
        きます。）を⾏い、当該通知に対して、本規約同意者が電磁的⽅法によって承諾の意思
        表⽰をすることによって成⽴します。
        </li>
      </ol>


      <h2 className="text-xl font-semibold mb-2">
        第６条（法令及び当社の指⽰の遵守）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
          本業務の内容であるクライアントの商品・サービスのプロモーション又は受託業務の実施にあたり、表示上の規制等、
      各種法令遵守（優良誤認表示・有利誤認表示をインフルエンサーの場合において行わないことを含みますが、これに限りません。）が要求される場合には、
      本規約同意者はこれを遵守するものとします。
        </li>
        <li className="mb-2">
          本業務の提供⼜はこれに関連する、当社によるクライアントに対する本サービスの提供（これらに関連して問題が⽣じた場合における対応を含みます。）について、当社から指⽰があった場合には、本規約同意者はこれに従うものとします。また、クライアント⼜はその他の第三者から 本規約同意者に対して直接連絡があった場合、本規約同意者はまず当社に報告し、単独でこれに対応しないものとします。本項の規定は、本基本契約⼜は本個別契約が終了した後においても適⽤されるものとします。
        </li>
      </ol>
      
      <h2 className="text-xl font-semibold mb-2">
        第７条（インフルエンサーの協⼒）
      </h2>
      <p className="mb-2">
        インフルエンサーは、⾃⼰の名称、サービスマーク、ロゴ⼜は商標を、本個別契約に定める⽬的で当社及びクライアントが使⽤することにつき、予めこれを許諾するものとします。
      </p>
      
      <h2 className="text-xl font-semibold mb-2">
        第８条（報告）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
          本規約同意者は、当社からの請求があった場合には、本業務の履⾏の状況を当社に対して直ちに報告するものとします。
        </li>
        <li className="mb-2">
          本規約同意者が、本業務の履⾏に⽀障が⽣じるおそれのある事項が発⽣し⼜は発⽣するおそれがあることを知った場合には、その旨を直ちに当社に報告するものとし、
      今後の対応方針について協議するものとします。
        </li>
      </ol>
      
      <h2 className="text-xl font-semibold mb-2">
        第９条（再委託）
      </h2>
      <p className="mb-2">
        本規約同意者は、当社の事前の書⾯による同意がある場合に限り、本個別契約により特定された本業務の全部⼜は⼀部を第三者に再委託することができるものとします。
      この場合において、本規約同意者は、かかる再委託された本業務にかかる第三者の作為・不作為について、⼀切の責任を負うものとします。
      </p>
      
      <h2 className="text-xl font-semibold mb-2">
        第１０条（資料等）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
            本規約同意者は、から提供された本業務の遂行に必要な資料・情報等（以下「資料等」という）がある場合、本件業務以外の用途に使用してはならず、善良なる管理者の注意義務をもって使用・保管・管理するものとします。
        </li>
        <li className="mb-2">
            提供された資料等に含まれる知的財産権は当然に留保されるものとします。
        </li>
            提供された資料等が不要となった場合、本契約が解除された場合、又はからの要請があった場合、本規約同意者は提供された資料等を速やかに返却するものとします。
        <li className="mb-2">
        </li>
      </ol>

      <h2 className="text-xl font-semibold mb-2">
       第 １１条（検収）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
         本規約同意者は、本個別契約に定める期⽇までに、本個別契約において特定された本成果物の制作を完了します。
        </li>
        <li className="mb-2">
         本個別契約において特定された本業務が、本成果物の納⼊を⽬的とするものである場合、本規約同意者は、当該本個別契約に定める納期までに本成果物を納⼊するものとし、
      当社は、14 ⽇以内（業務受託者にあっては、別途個別契約で定める期日まで）に、その内容が本個別契約に合致するものであるか否かの検査を⾏います。
        </li>
        <li className="mb-2">
         前項の検査の結果、本成果物の内容が本個別契約に合致したものではない場合、当社は、検査期間内にその旨を本規約同意者に通知します。
        </li>
        <li className="mb-2">
         本規約同意者は、前項の通知に従い、当社の定める期間内に、速やかに⾃⼰の負担により本成果物の内容を本個別契約に合致するものとする修正等を⾏い、再度の納⼊を⾏います。
      また、当社は本規約同意者に対して、本規約同意者の費用と負担で本成果物の、代替物の引渡し、又は不足分の引渡し等の自ら指定した方法による履行の追完を請求することもできます。
        </li>
        <li className="mb-2">
         第２項の検査の結果、本成果物の内容が本個別契約に合致したものである場合には、当社は、検査に合格した旨を本規約同意者に通知するものとします。
        </li>
        <li className="mb-2">
         検査期間を過ぎても、第３項⼜は前項に基づく通知がない場合は、本成果物については、検査期間満了⽇をもって検査に合格したものとみなします。
        </li>
        <li className="mb-2">
         インフルエンサーは、前⼆項に従って検査に合格した本成果物について、本個別契約に定める条件に従って、本媒体へのアップロード・投稿作業等を⾏うものとします
        </li>
        <li className="mb-2">
            成果物について、検収が完了する前に滅失、損傷その他の損害(以下「滅失等」という。)が生じた場合には、当該滅失等は、それが当社の責めに帰すべき事由によって生じたときを除き、本規約同意者の負担とする。
        </li>
        <li className="mb-2">
            当事者双方の責めに帰することができない事由によって前項の滅失等が生じ、これにより本規約同意者がその債務を履行することができなくなった場合には、当社は、本件委託料の支払いを拒むことができる。
        </li>
        <li className="mb-2">
            第2項の検査完了後、本成果物の内容が本個別契約に合致しないことが発見された場合、当社が当該事項を知った時から1年以内に通知することにより、第4項と同様の履行の追完を請求することができます。
        </li>
        <li className="mb-2">
            当社は、前項の請求とともに、又はこれに代えて、本件委託料の減額又は損害賠償を請求することができ、それとともに個別契約を解除することができるものとします。
        </li>
      </ol>



      <h2 className="text-xl font-semibold mb-2">
       第 １２条（委託料）
      </h2>
      <p className="mb-4">
      当社は、本規約同意者に対して、本業務の対価として本個別契約において定める委託
料及びこれにかかる消費税等を、別途当社及び本規約同意者が本個別契約において合
意する⽀払⽇及び⽀払⽅法により⽀払うものとします。なお、かかる⽀払いに必要な費⽤
は、当社の負担とします。
      </p>

      <h2 className="text-xl font-semibold mb-2">
      第 １３条（インフルエンサーの知的財産権）
      </h2>

      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
         本サービスに関する知的財産権は、全て当社に帰属します、
        </li>
        <li className="mb-2">
         本業務及び本成果物に関する知的財産権は、インフルエンサーに帰属するものとしま
         す。
        </li>
        <li className="mb-2">
        インフルエンサーは、本個別契約にて定める範囲内において、本業務により生じた成果物の利用を使用頻度の制約及び追加の対価なしに許諾するものとします。かかる利用許諾の対価は、委託料に含まれるものとします。
        </li>
        <li className="mb-2">
        本個別契約における二次利用範囲および使用期間の他、当社及び広告主の会社案内・事 業報告書、会社年史等の記録物及び社内用データベース(イントラネットを含む。)への本 成果物の掲載をインフルエンサーは承諾するものとします。
        </li>
        <li className="mb-2">
        インフルエンサーは、本サービス、本業務及び本成果物に関連して、著作者人格権(著 作権法第 19 条乃至第 21 条に定める権利をいいます。以下同じ。)を行使せず、又は第三者 をしてかかる著作者人格権を行使させないものとします。
        </li>
      </ol>


      <h2 className="text-xl font-semibold mb-2">
      第１４条（受託業務者の知的財産権非侵害の保証）
      </h2>
      <p>
       業務受託者は、本業務、又は本件業務に係る成果物が第三者の知的財産権を侵害しないこと、また、本業務について第三者の機密情報を不正使用していないことを表明し、かつ保証するものとし、当社が第三者から知的財産権の侵害に関する訴訟を提起され又は権利主張される等の紛争が生じた場合には、自らの費用と責任において、当該紛争を解決するものとし、当該紛争によって当社に生じた一切の損害（弁護士費用を含む）を賠償する責任を負うものとします。但し、かかる侵害又は不正使用が当社の責に帰すべき事由により生じた場合はこの限りでない。
      </p>

      <h2 className="text-xl font-semibold mb-2">
      第１５条（受託業務者の本業務に含まれる著作権等）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
            本業務の内容に著作物が含まれる場合、当該著作物（以下「本件著作物」という）に係る一切の著作権（著作権法第27条及び第28条に規定する権利を含む。
         以下「本件著作権」という。）は、当社が本業務にかかる引渡しを受けた時、受託業務者から当社に移転します。なお、かかる著作権の対価は、委託報酬に含まれます。
        </li>
        <li className="mb-2">
            本業務に関連して、著作者人格権、肖像権その他法律上移転不可能な権利（以下、総称して「著作者人格権等」という）が存在する場合、受託業務者は
            本件著作物の全部又は一部を利用し又は当社の顧客その他の第三者に利用許諾することについて、著作者人格権を行使しないものとします。
            また、著作者人格権等が第三者の保有に係るときは、当該第三者が著作者人格権等を行使しないよう必要な措置をとる。なお、かかる不行使の対価は、委託報酬に含まれるものとします。
        </li>
      </ol>


      <h2 className="text-xl font-semibold mb-2">
      第 １６条（禁⽌事項）
      </h2>
      <p className="mb-4">
        本規約同意者は、本業務の実施にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。
      </p>
       <ul className="mb-4">
       <li className="mb-2">
        ①法令、若しくは当社若しくは本規約同意者が所属する業界団体の内部規則等に違反
        する⾏為⼜は犯罪⾏為に関連する⾏為
       </li>
       <li className="mb-2">
        ②当社、クライアント⼜はその他の第三者の知的財産権、肖像権、プライバシーの権利、名
        誉、その他の権利⼜は利益を侵害する⾏為
       </li>
       <li className="mb-2">
        ③本サービスの運営を妨害するおそれのある⾏為
       </li>
       <li className="mb-2">
        ④第三者に成りすます⾏為
       </li>
       <li className="mb-2">
        ⑤当社の事前の承諾を得ることなく、本個別契約に関連するクライアントに対して直接連
        絡⼜は情報を提供する⾏為
       </li>
       <li className="mb-2">
        ⑥各本個別契約に係る本業務の終了後 2 年以内に、当該本個別契約に関連するクライアン
        トとの間で、当社を介することなく取引を⾏い、⼜は契約を締結する⾏為
       </li>
       <li className="mb-2">
        ⑦その他、当社が不適切と判断する⾏為
       </li>
      </ul>

      <h2 className="text-xl font-semibold mb-2">
      第 １７ 条(インフルエンサーによる第三者の広告出演の禁止)
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
        本個別契約において「第三者の広告出演の禁止」をありとした場合、次項以降の規定が 適用されます。
        </li>
        <li className="mb-2">
        インフルエンサーは、本個別契約の有効期間中において、本個別契約記載の禁止範囲、 期間等に従い、役務提供を行わず、インフルエンサーの肖像等の使用を許諾しないものとし ます。
        </li>
        <li className="mb-2">
        インフルエンサーは、第三者とマーケティングに関する契約を締結するにあたり、前項 に該当するかどうかの判断が難しい場合には、契約締結前に発注者にその旨を通知し、協議 しなければならないものとする。
        </li>
        <li className="mb-2">
        前項の出演の制限は、テレビ・ラジオ番組、演劇、コンサートへの出演その他タレント の本来の芸能活動に関するものについては、適用されないものとします。ただし、競合商品 の製造・販売者が単独スポンサーとなる番組に出演するなど特別な事情のある場合には、当 社へ事前に通知するものとします。
        </li>
      </ol>


      <h2 className="text-xl font-semibold mb-2">
      第 １８条（利⽤停⽌・申請抹消等）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
        当社は、本規約同意者が、以下の各号のいずれかの事由に該当すると判断した場
        合、本規約同意者への事前の通知⼜は催告を要することなく、本規約同意者として
        の申請を抹消、⼜は本基本契約若しくは本個別契約を直ちに解除することができます。
          <ul className="mb-4">
           <li className="mb-2">
            ①本規約⼜は本個別契約のいずれかの条項に違反し、当社がかかる違反の是正を事前に催
            告した後、合理的な期間内に是正されない場合
           </li>
           <li className="mb-2">
            ②申請事項に虚偽の事実があることが判明した場合
           </li>
           <li className="mb-2">
            ③当社からの問い合わせその他の回答を求める連絡に対して 7 ⽇間以上応答がない場合
           </li>
           <li className="mb-2">
            ④第 3 条第 4 項各号に該当し、⼜は第 16 条各号に該当する⾏為をした場合
           </li>
           <li className="mb-2">
            ⑤その他、本業務の実施、本規約同意者としての申請の継続、⼜は本基本契約若しくは
            本個別契約の継続を適当でないと当社が判断した場合
           </li>
            <p>業務受託者の場合は、次の場合も含みます。</p>
           <li className="mb-2">
            ⑥差押、仮差押、仮処分、強制執行又は競売の申し立てを受けた場合
           </li>
           <li className="mb-2">
            ⑦破産、民事再生、会社更生を自ら申立て、若しくは申立てを受け又は清算を開始した場合（ただし現在継続している手続を除きます）。
           </li>
           <li className="mb-2">
            ⑧手形又は小切手の不渡りを１回でも出したとき。
           </li>
           <li className="mb-2">
            ⑨租税の滞納処分を受けたとき。
           </li>

          </ul>
        </li>
        <li className="mb-2">
          当社は、本条に基づき当社が⾏った⾏為により本規約同意者に⽣じた損害につい
          て⼀切の責任を負いません。
        </li>
      </ol>

      <h2 className="text-xl font-semibold mb-2">
        第１９条（業務委託料の減額）
      </h2>
      <p>
        当社は、 本規約同意者が前条第1項各号に該当する場合、又は本業務の内容等について疑義がある場合には、第12条所定の委託料の支払いを留保又は減額することができるものとします。ただし、下請代金支払遅延等防止法の適用を受けるときは同法の規定が優先します。
      </p>
        
      <h2 className="text-xl font-semibold mb-2">
        第２０条（中途解約）
      </h2>
      <p>
        当社は、本契約の有効期間中であっても、本規約同意者に対する書面又は電磁的方法による通知により、本契約の全部又は一部を直ちに解除することができるものとします。
      </p>


      <h2 className="text-xl font-semibold mb-2">
      第 ２１条（免責）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
          何らかの理由により当社が責任を負う場合であっても、当社は、インフルエンサーが被
          った損害につき、過去 12 ヶ⽉間に当社がインフルエンサーに⽀払った対価の⾦額を超えて
          賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及
          び逸失利益にかかる損害については、賠償する責任を負わないものとします。
        </li>
        <li className="mb-2">
          本サービス⼜は当社ウェブサイトに関連してインフルエンサーと第三者との間におい
          て⽣じた連絡、取引関係、紛争等については、インフルエンサーは⾃らの責任及び費⽤負担
          において対応するものとし、当社は⼀切責任を負いません。
        </li>
      </ol>

      <h2 className="text-xl font-semibold mb-2">
      第 ２２ 条（機密保持）
      </h2>
      <p className="mb-4">
        本規約同意者は、本サービスに関連して当社が本規約同意者に対して開⽰した
        本規約同意者の事業情報、営業情報若しくは製品・サービス情報、⼜はその他の
        ⼀切の情報（本個別契約の存在及びその条件を含みます。以下「秘密情報」といいます。）
        について、当社の事前の書⾯による承諾がある場合を除き、秘密として取り扱い、本個別契
        約に定める⽬的以外の⽬的で利⽤しないものとします。また、本規約同意者は、秘密情
        報に関して善良なる管理者の注意をもって保管するものとし、当社から問い合わせがあっ
        た場合には、その保管状況について直ちに必要な回答を⾏い、また当社から要求があった場
        合には、その要求に従い、秘密情報を直ちに返還⼜は破棄するものとします。本条の規定は、
        本基本契約⼜は本個別契約が終了した後においても適⽤されるものとします。
      </p>

      <h2 className="text-xl font-semibold mb-2">
      第 ２３ 条（個⼈情報の取り扱い）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
          本規約同意者は、本基本契約⼜は本個別契約に関連して当社⼜はクライアントか
          ら受領した個⼈情報を本業務を履⾏する⽬的の範囲内でのみ使⽤し、第三者に開⽰⼜は漏
          洩せず、また、個⼈情報の保護に関する法令及びガイドラインを遵守するものとします。
        </li>
        <li className="mb-2">
          本規約同意者は、当該個⼈情報の管理に必要な措置を講ずるものとします。
        </li>
        <li className="mb-2">
          本規約同意者は、当社が定めるプライバシーポリシーに同意するものとします。
        </li>
        <li className="mb-2">
          本条の規定は、本基本契約及び本個別契約終了後も有効に存続するものとします。
        </li>
      </ol>

      <h2 className="text-xl font-semibold mb-2">
      第 ２４ 条（本規約同意者の損害賠償責任）
      </h2>
      <p className="mb-4">
        本規約同意者が本規約、本基本契約⼜は本個別契約の各条項に違反して、当社、クライ
        アント、それらの関係者⼜はその他第三者に損害を与えた場合は、本規約同意者はその
        損害を賠償するものとします。
      </p>
      

      <h2 className="text-xl font-semibold mb-2">
      第 ２５ 条（本規約の変更）
      </h2>
      <p className="mb-4">
        当社は、⾃らの単独の裁量で本規約を変更できるものとします。当社は、本規約を変更する
        場合には、本規約の変更後の内容及び当該変更の効⼒発⽣⽇を本規約同意者に通知し、
        ⼜は当社のウェブサイト上に表⽰し、周知するものとし、当該変更内容の通知後、本規約同意者
        が当社の定める期間内に申請抹消の⼿続をとらなかった場合には、本規約同意者は、本規約の変更に同意したものとみなします。
      </p>

      <h2 className="text-xl font-semibold mb-2">
      第 ２６ 条（契約上の地位譲渡等）
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
         本規約同意者は、当社の書⾯による事前の承諾なく、本基本契約及び本個別契約上
         の地位⼜は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、担保設定、その
         他の処分をすることはできません。
        </li>
        <li className="mb-2">
         当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本基
         本契約及び本個別契約上の地位、本規約に基づく権利及び義務並びに本規約同意者の
         申請事項その他の関連情報を当該事業譲渡の譲受⼈に譲渡することができるものとし、
         本規約同意者は、かかる譲渡につき本項において予め同意したものとします。なお、本項
         に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆ
         る場合を含むものとします。
        </li>
     </ol>


    <h2 className="text-xl font-semibold mb-2">
     第 ２７ 条（反社会的勢⼒の排除）
    </h2>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
        当社及び本規約同意者は⾃ら及び⾃らの再委託先等が、現在、暴⼒団、暴⼒団員、
        暴⼒団員でなくなった時から５年を経過しない者、暴⼒団準構成員、暴⼒団関係企業、総会
        屋等、社会運動等標ぼうゴロ⼜は特殊知能暴⼒集団等、その他これらに準ずる者（以下これ
        らを「反社会的勢力等」といいます。）に該当しないこと、及び次の各号のいずれにも該当しな
        いことを表明し、かつ将来にわたっても該当しないことを確約します。
        <ul className="mb-4">
          <li className="mb-2">
          ①暴⼒団員等が経営を⽀配していると認められる関係を有すること
          </li>
          <li className="mb-2">
          ②暴⼒団員等が経営に実質的に関与していると認められる関係を有すること
          </li>
          <li className="mb-2">
          ③⾃⼰若しくは第三者の不正の利益を図る⽬的⼜は第三者に損害を加える⽬的をもってす
          るなど、不当に暴⼒団員等を利⽤していると認められる関係を有すること
          </li>
          <li className="mb-2">
          ④暴⼒団員等に対して資⾦等を提供し、⼜は便宜を供与するなどの関与をしていると認め
          られる関係を有すること
          </li>
          <li className="mb-2">
          ⑤役員⼜は経営に実質的に関与している者が暴⼒団員等と社会的に⾮難されるべき関係を
          有すること
          </li>
         </ul>
        </li>
        <li className="mb-2">
          当社及び本規約同意者は、⾃ら及び⾃らの再委託先等が⾃ら⼜は第三者を利⽤し
            て、次の各号に該当する⾏為を⾏わないことを確約します。
          <ul className="mb-4">
            <li className="mb-2">
            ①暴⼒的な要求⾏為
            </li>
            <li className="mb-2">
            ②法的な責任を超えた不当な要求⾏為
            </li>
            <li className="mb-2">
            ③取引に関して、脅迫的な⾔動をし、⼜は暴⼒を⽤いる⾏為
            </li>
            <li className="mb-2">
            ④⾵説を流布し、偽計を⽤い⼜は威⼒を⽤いて相⼿⽅の信⽤を毀損し、⼜は相⼿⽅の業務を
            妨害する⾏為
            </li>
            <li className="mb-2">
            ⑤その他前各号に準ずる⾏為
            </li>
           </ul>
        </li>
        <li className="mb-2">
          当社及び本規約同意者は、相⼿⽅が暴⼒団員等若しくは第１項各号のいずれかに
        該当し、若しくは前項各号のいずれかに該当する⾏為をし、⼜は第１項の規定に基づく表
        明・確約に関して虚偽の申告をしたことが判明し、相⼿⽅との取引を継続することが不適切
        である場合には、何ら催告をしないで直ちに本基本契約及び本個別契約を解除することが
        できます。なお、本基本契約及び本個別契約の解除に伴い相⼿⽅に費⽤が発⽣し、若しくは
        損害が⽣じた場合であっても、他の規定にかかわらず相⼿⽅は当社及び本規約同意者
        に対し当該費⽤及び損害の請求を⾏わないものとします。
        </li>
        <li className="mb-2">
            本規約同意者から再委託先等への再委託がある場合の契約解除の取扱いは以下の
            とおりとします。
          <ul className="mb-4">
            <li className="mb-2">
                ①当社は、本規約同意者の再委託先等が、暴⼒団員等若しくは第１項各号のいずれかに
                該当し、若しくは第２項各号のいずれかに該当する⾏為をし、⼜は第１項の規定に基づく
                表明・確約に関して虚偽の申告をしたことが判明した場合には、以下の措置を取ることが
                できるものとします。
                ・当社は何ら催告をしないで直ちに本基本契約及び本個別契約を解除することができる
                ものとします。
                ・当社は本規約同意者に対し再委託先等の変更を直ちに⾏うことを求めることがで
                きます。
            </li>
            <li className="mb-2">
                ②本基本契約⼜は本個別契約の解除⼜は再委託先等の変更に伴い本規約同意者に費⽤
                が発⽣し、若しくは損害が⽣じた場合であっても、他の規定にかかわらず本規約同意者
                は当社に対し当該費⽤及び損害の請求を⾏いません。
            </li>
           </ul>
        </li>
        <li className="mb-2">
          第３項及び前項の事由により、本基本契約⼜は本個別契約の解除⼜は再委託先等の変
    更を請求した者に費⽤が発⽣し、若しくは損害が⽣じた場合には、その相⼿⽅に対して費⽤
    の償還⼜は損害の請求を⾏うことができます。
        </li>
     </ol>


    <h2 className="text-xl font-semibold mb-2">
    第 ２８ 条（準拠法及び管轄裁判所）
    </h2>

      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">
    本規約、本基本契約及び本個別契約の準拠法は⽇本法とします。
        </li>
        <li className="mb-2">
    本規約、本基本契約⼜は本個別契約に起因し、⼜は関連する⼀切の紛争については、東
    京地⽅裁判所を第⼀審の専属的合意管轄裁判所とします。
        </li>
     </ol>

    <p className="mt-3">
   【制定日・改訂日】
    </p>
    <p>
    2023年5月24日 制定
    </p>
    <p>
    2023年9月19日 改訂
    </p>

    </div>
  );
}

export default Terms;
