import React, { useState , useEffect} from 'react';
import Modal from 'react-modal';
import 'tailwindcss/tailwind.css';

import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';

// Ensure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function App() {
  useEffect(() => {
    document.title = '利用規約とプライバシーポリシー';
  }, []);

  const [termsIsOpen, setTermsIsOpen] = useState(false);
  const [privacyIsOpen, setPrivacyIsOpen] = useState(false);

  const openTerms = () => setTermsIsOpen(true);
  const closeTerms = () => setTermsIsOpen(false);

  const openPrivacy = () => setPrivacyIsOpen(true);
  const closePrivacy = () => setPrivacyIsOpen(false);

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-4">
      <button 
        onClick={openTerms} 
        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-16 border border-gray-400 rounded"
      >
      利用規約はこちら
      </button>
      <button 
        onClick={openPrivacy} 
        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
      >
      プライバシーポリシーはこちら
      </button>

      <Modal
        isOpen={termsIsOpen}
        onRequestClose={closeTerms}
        contentLabel="Terms"
        className="flex items-center justify-center outline-none"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 1)',
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            background: 'none',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: 'none',
            outline: 'none',
            padding: '0'
          }
        }}
      >
        <div className="bg-white rounded p-5 w-screen h-screen overflow-auto">
          <Terms />
          <button onClick={closeTerms} className="mt-4 px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700">閉じる</button>
        </div>
      </Modal>

      <Modal
        isOpen={privacyIsOpen}
        onRequestClose={closePrivacy}
        contentLabel="Privacy"
        className="flex items-center justify-center outline-none"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 1)',
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            background: 'none',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: 'none',
            outline: 'none',
            padding: '0'
          }
        }}
      >
        <div className="bg-white rounded p-5 w-screen h-screen overflow-auto">
          <Privacy />
          <button onClick={closePrivacy} className="mt-4 px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700">閉じる</button>
        </div>
      </Modal>
    </div>
  );
}

export default App;


