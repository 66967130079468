// Privacy.js
import React from 'react';
import 'tailwindcss/tailwind.css';

function Privacy() {
  return (
      <div>
    <h1 className="title text-2xl font-bold mb-4">プライバシーポリシー</h1>
    <div className="date">2018.03.06</div>
    <p className="mb-4">当社における個人情報の取扱いについて</p>
    <h2 className="text-xl font-semibold mb-2">1 お客さまの個人情報の利用目的について</h2>
    <p className="mb-4">
      <p className="mb-4">
      当社において、事業を行うにあたり、各種の申込書等の書面、Web等の画面、口頭等の方法、アンケート調査、商談、契約の締結などの機会を通じて、また、不動産登記簿、商業登記簿、電話帳などの一般に公開されている媒体からも、お客さまの住所・氏名・郵便番号・電話番号・FAX番号・メールアドレスなどの個人情報をお預かりいたします。なお、お客様との電話応対時においては、ご注文・ご意見・ご要望などの正確な把握のために通話を録音させて頂く場合がございます。これらの個人情報は、次の目的で利用させていただきます。
    </p>

    </p>
    <p className="mb-4">
      <strong className="font-semibold">【お預かりした個人情報の利用目的】</strong><br/>
      <p>当社は、「個人情報の保護に関する法律」を遵守し、当社または当社グループ会社が行う次の事業に関するご案内・ご提案、契約の締結・履行、アフターサービスの実施、お客さまへの連絡・通信、新しい商品・サービスの開発、及びお客さまに有益と思われる情報の提供などのために、お客さまの個人情報を利用させていただきます。</p>
      <ol className="list-decimal list-inside mb-4">
        <li className="mb-2">プロモーション事業</li>
        <li className="mb-2">ブランディング事業</li>
        <li className="mb-2">キャスティング事業</li>
        <li className="mb-2">クリエイティブ事業</li>
      </ol>
      <p>具体的には、当社の事業活動や契約の実現とともに、下記のような目的などで利用させていただきます。</p>
      <br />

      <ol className="list-decimal list-inside mb-4">
      <li className="mb-2">商品・サービスの提供、変更又は停止のためのご本人確認、商品・サービスの提供、商品・サービスの提供に伴う対価の請求その他サービスの提供に関連する付随業務の遂行</li>
      <li className="mb-2">当社又は提携先で取り扱う商品・サービスに関する情報の案内</li>
      <li className="mb-2">新商品・新サービスの検討及び開発</li>
      <li className="mb-2">サイト運営設備並びにこれらに附帯する設備の開発、運用及び保守</li>
      <li className="mb-2">マーケティング調査その他の調査研究</li>
      <li className="mb-2">電子メール配信サービスや刊行物などの発送のため</li>
      <li className="mb-2">懸賞及びキャンペーン等の実施</li>
      <li className="mb-2">個人情報保護法に基づく開示請求等その他お客様からのお問い合わせやご相談の対応</li>
      <li className="mb-2">株主の管理</li>
      <li className="mb-2">会計監査上の確認作業のため</li>
      <li className="mb-2">従業員等の雇用及び人事管理</li>
      <li className="mb-2">その他当社の事業に付帯・関連する事項のため</li>
    </ol>

    </p>
    <h2 className="font-bold text-2xl my-4">2 個人情報の保護・管理</h2>
    <p className="mb-4">当社では、個人情報の取扱いに関する社内規定を定め、個人情報保護法をはじめとする個人情報保護に関する関係法令を遵守します。また、社員等の教育に努め、個人情報保護の重要性を周知しております。<br/>
    なお、当社では、個人情報の保護に関する体制、対策を定期的に見直し、改善に努めます。</p>
    
    <h2 className="font-bold text-2xl my-4">3 個人情報の取扱いの委託</h2>
    <p className="mb-4">当社においては、業務を円滑に遂行するために、業務を委託し、当該委託先に対し必要な範囲で個人情報を預託する場合があります。その場合、当社は、委託先との間で取り扱いに関する契約を結ぶなど、適切な監督を行います。</p>
    
    <h2 className="font-bold text-2xl my-4">4 個人情報の第三者提供について</h2>
    <p className="mb-4">当社は、以下のいずれかに該当する場合を除きお預かりした個人情報を第三者に提供いたしません。</p>
    
    <ol className="list-decimal list-inside mb-4">
    <li>お客さまから事前にご同意をいただいた場合</li>
    <li>利用目的の達成に必要な範囲内において外部委託した場合</li>
    <li>法令に基づき提供を求められた場合</li>
    <li>人の生命、身体または財産の保護のために必要な場合であって、お客さまの同意を得ることが困難である場合</li>
    <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客さまの同意を得ることが困難である場合</li>
    <li>国または地方公共団体などが法令の定める事務を実施するうえで、協力する必要がある場合であって、お客さまの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
    </ol>

    
    <h2 className="font-bold text-2xl my-4">5 個人情報の開示等に関するお問い合わせ先</h2>
    <p className="mb-4">個人情報に関する開示・訂正・利用停止等の要請は下記までご連絡お願い致します。</p>

    <p className="mb-4 p-consent__box">株式会社ミンツプランニング 個人情報保護管理担当<br/>
    連絡先<br/>
    〒150-0012東京都渋谷区広尾１丁目3−18　広尾オフィス・ビル10階<br/>
    株式会社ミンツプランニング 総務部 個人情報保護相談窓口<br/>
    お問い合わせ contact-info@mintzplanning.com</p>

</div>

  );
}

export default Privacy;

